@import "variables";
$font-family-sans-serif: 'Inter', sans-serif;

* {
  box-sizing: border-box;
  font-family: $font-family-sans-serif !important;
  text-transform: inherit !important;
}

body {
  width: 100%;
  // background: #eff3fd;
  background: rgb(244, 246, 248);
  // min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;

  h1 {
    @media (min-width: 1200px) {
      & {
        font-size: 2.7rem;
      }
    }

    font-weight: 600;
  }

  .home_pic {
    border-radius: 12px;
  }

  .link {
    color: $purple;
  }

  nav.navbar {
    position: fixed !important;
    top: 0;
    box-shadow: inset 0 -1px 1px #e5eaf2;
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    backdrop-filter: blur(12px);
    // background-color: #eff3fda8;
    z-index: 10;
    min-height: 10vh;

    .nav-link {
      font-weight: 500;

      &.active {
        color: $purple !important;

        // &::before {
        //   background: #2b50d2;
        //   content: "";
        //   height: 1px;
        //   left: 0;
        //   position: absolute;
        //   top: 58px;
        //   width: 100%;
        // }
      }
    }

    .navbar-collapse {
      flex-grow: inherit;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    border-radius: 10px !important;
    transition: .2s;

    &.MuiButton-outlined {
      &:hover {
        border: 1px solid $purple;
      }
    }
  }

  .loginBtn {
    background: rgba(64, 87, 109, .07);
    height: 59px;
    color: black;
    box-shadow: none;

    &:hover {
      background: rgba(57, 76, 96, .15);
    }
  }

  button.blue,
  a.blue {
    background: $cyan !important;
    color: white !important;
    // width: 100%;
    border: 1px solid $cyan;
  }

  button.white {
    background: $purple !important;
    border: 1px solid $purple;
    color: white !important;

    &:disabled {
      opacity: .5;
    }
  }

  button.gray {
    background: $gray !important;
    border: 1px solid $gray;
    color: $purple !important;

    &:disabled {
      opacity: .5;
    }
  }

  button.error {
    color: red;
  }

  // .logout {
  //   color: red !important;
  //   cursor: pointer;
  //   font-weight: 500;
  // }



  // .about {
  //   background-color: rgb(255, 255, 255);
  //   color: rgb(42, 53, 71);
  //   transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  //   border-radius: 8px;
  //   box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  //   overflow: hidden;
  //   padding: 1rem;

  //   h5 {
  //     font-size: 18px;
  //   }

  //   p {
  //     font-size: 14px;
  //   }
  // }
  .about {
    overflow: hidden;
    margin-top: 14vh;

    .row {
      background: white;
      // border: 1px solid $purple;
      border-radius: 20px;

      a {
        color: $purple;
      }
    }
  }

  .login {
    // min-height: 100vh;
    margin-top: 10vh;
    overflow: hidden;

    .row {
      background: white;
      // border: 1px solid $purple;

      border-radius: 20px;
    }

    form,
    p {
      a {
        color: $purple;
        font-size: 16px;
        text-align: end;
        display: inline-block;
        font-weight: 500;
        transition: .2s;
        width: fit-content;
        margin-left: auto;

        &:hover {
          color: $purple;

          opacity: .7;
        }
      }
    }

    form {
      span {
        color: $red;
      }

      svg {
        cursor: pointer;
      }

      &.otp_form {
        div {
          justify-content: space-between;

          input {
            width: 12% !important;
            height: 50px;
            border-radius: 8px;
            border: 1px solid $purple;
          }

          span {
            color: $purple;
          }
        }
      }
    }
  }

  .home {
    min-height: 100vh;
  }

  .error-message {
    color: red;
    margin-bottom: 0;
  }

  .account {
    width: 100%;
    min-height: 85vh;
    margin-top: 15vh;

    .loading {
      left: 50%;
      top: 50%;
      font-size: 36px;
      // font-family: serif;
      font-weight: bold;
      letter-spacing: 3.4px;
      // text-transform: capitalize;
      // position: absolute;
      // overflow: hidden;
      transform: translate(0%, 0%);

      &:before {
        color: $gray;
        content: attr(data-loading-text);
      }

      &:after {
        top: 0;
        left: 0;
        width: 0;
        opacity: 1;
        color: $purple;
        overflow: hidden;
        position: absolute;
        content: attr(data-loading-text);
        animation: loading 5s infinite;
      }

      @keyframes loading {
        0% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }

    .menus {
      // background: $blue;
      // position: relative;
      // min-height: 100vh;
      border-right: 1px solid $gray;
      position: relative;
      z-index: 1;
      // color: white;

      hr {
        color: $gray;
        margin: 0;
        opacity: 1;
      }

      li {
        a {
          width: 100%;
          color: #818198;
          margin: 0;
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem;
          border-radius: 8px;
          transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          &:hover {
            background: rgba(0, 0, 0, 0.04);
            color: black;
            // color: rgb(93, 135, 255);
          }

          &.active {
            color: white;
            background: $purple;

            img {
              fill: white;
              filter: brightness(12.5);
            }
          }
        }
      }
    }

    .appeals-table {
      border-radius: 8px;

      &.with-details {
        tr {
          &:hover {
            td {
              color: $purple;
              // opacity: .8;
            }
          }

          td {
            text-decoration: underline;

          }
        }

      }

      tbody tr {
        transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }

    .draggable {
      .drag {
        cursor: grab;

        &::after {
          content: url("./components/Home/img/drag.svg")
        }
      }
    }

    .barchart2 {
      .MuiChartsAxis-directionY {
        .MuiChartsAxis-tickLabel {
          transform: translateX(3px);
        }
      }

      &>div {
        width: 100%;

        @media screen and (max-width: 992px) {
          height: inherit;
        }
      }
    }

    .stepper {
      flex-wrap: wrap;

      .Mui-active,
      .Mui-completed,
      .Mui-checked,
      .MuiCheckbox-indeterminate {
        color: $purple
      }

      .MuiStepLabel-iconContainer {
        padding-right: 0;
      }

      // .MuiSvgIcon-root {
      //   font-size: 1.7rem;
      // }
      &~.MuiGrid-root {
        width: 100%;
        margin-left: 0;

        .MuiCardHeader-root {
          padding-right: 0;

          // span{
          //   display: inline;
          // }
          .MuiCardHeader-avatar {
            margin: 0;
          }
        }

        button {
          min-width: inherit;
        }
      }
    }

    .MuiPagination-root {
      button {
        &.Mui-selected {
          background-color: $purple;

        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 28px;
}

::-webkit-scrollbar-track {
  background: #f2f2f5;
  border-radius: 28px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 28px;
  // cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  opacity: .4;
}